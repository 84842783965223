.guides-home {
  .heading {
    font-size: 40px;
    line-height: 48px;
    font-weight: 600;
    margin-bottom: 80px;
  }
  .list-section {
    &.cards {
      gap: 40px !important;
      .white-card {
        width: 360px !important;
        height: 229px !important;
      }
    }
  }
  .info-card {
    background-color: transparent;
    border: solid 2px #fff;
    color: #556270;
    text-align: center;
    display: flex;
    align-items: center;
    .card-top-margin,
    .margin-line,
    .dotted-line,
    .card-line {
      display: none;
    }
  }
  .wrapper-section {
    .btn-secondary {
      padding: 12px 32px;
      border-radius: 8px;
    }
    section {
      &:nth-child(2) {
        padding-top: 0 !important;
      }
    }
  }
  .cta-w-image {
    gap: 40px 20px;
    margin-bottom: 0 !important;
    article {
      padding: 80px 100px !important;
      &:first-child {
        background-color: #fff !important;
        box-shadow: 0 1px 2px 0 rgba(48, 58, 71, 0.2),
          0 1px 3px 0 rgba(48, 58, 71, 0.1);
      }
      &:nth-child(2) {
        border: 2px solid #fff !important;
        a {
          background-color: transparent;
          border: 1px solid $green-800;
          color: $green-800;
        }
      }
      @include respond-between(mobile, tablet_max) {
        padding: 80px !important;
      }
      a {
        border-radius: 8px;
        padding: 12px 32px;
      }
    }
  }
  .home-masonry {
    &::after {
      content: none;
    }
  }
  .featured-video {
    width: 50%;
  }
  .carousel {
    .video-highlight {
      width: calc(100% - 8px);
      display: block;
      margin-left: auto;
      margin-right: auto;
      padding-top: 3px;
      border-radius: 8px;
      overflow: hidden;
      height: auto !important;
    }
    .carousel-item {
      &:nth-child(3) {
        box-shadow: 0 2px 4px 0 rgba(48, 58, 71, 0.1),
          0 4px 8px 0 rgba(48, 58, 71, 0.14);
      }
    }
  }
  .signup-cta-section {
    .signup-cta-warper {
      text-align: center;
      color: #fff;
    }
    h2 {
      max-width: none;
      margin-bottom: 16px;
    }
    p {
      width: 460px;
    }
  }
  @include respond-below(mobile) {
    .hero--content {
      h1 {
        font-size: 40px;
      }
    }
    #default-container {
      .container {
        margin-right: 0;
        margin-left: 0;
        h2 {
          margin-left: 20px;
        }
      }
    }
    .lists-container {
      overflow-x: auto;
      position: relative;
      &::-webkit-scrollbar {
        display: none;
      }
      .list-section {
        &.cards {
          margin-right: 0px;
        }
        &::before {
          height: 2520px;
          top: 110%;
        }
        &.cards {
          gap: 20px !important;
          flex-wrap: nowrap !important;
          transition: transform 0.5s ease;
          padding: 10px 0px;
          .white-card {
            height: 265px !important;
            .margin-line {
              height: 91%;
            }
            .card-content {
              padding: 18px 17px 32px 28px;
            }
            &:first-child {
              margin-left: 20px;
            }
          }
        }
      }
    }
    .carousel {
      .carousel-item {
        &:nth-child(2) {
          box-shadow: 0 2px 4px 0 rgba(48, 58, 71, 0.1),
            0 4px 8px 0 rgba(48, 58, 71, 0.14);
        }
      }
    }
    .heading {
      max-width: 335px;
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 40px;
      margin-top: 80px;
      &:first-child {
        margin-top: 60px;
      }
    }

    .wrapper-section {
      .btn-secondary {
        padding: 14px 80px;
      }
      .home-masonry {
        h2 {
          font-size: 40px;
          line-height: 48px;
        }
      }
      .card--default {
        a {
          background-color: #fff;
          &:not(:first-child) {
            margin-top: 24px !important;
          }
        }
      }
      article {
        &:first-child {
          @include respond-above(tablet_max) {
            h2 {
              font-size: 40px;
              line-height: 48px;
              margin-bottom: 24px !important;
            }
          }
          p {
            margin-top: 0;
          }
        }
      }
    }
    .cta-w-image {
      article {
        padding: 20px !important;
      }
    }
    .signup-cta-warper {
      p {
        width: auto;
        text-align: left;
      }
    }
  }
  @media (min-width: 400px) and (max-width: 575px) {
    .cards {
      & > * {
        flex: 1 0 71% !important;
      }
    }
  }
  @media (max-width: 400px) {
    .cards {
      & > * {
        flex: 1 0 77% !important;
      }
    }
  }
}

.guides-page {
  @include respond-above(tablet_max) {
    .card--default.card--feature {
      &.has-three-col {
        max-width: 862px;
        margin: 0 auto;
      }
      & .icon-title-wrap figure {
        margin-left: auto;
        margin-right: auto;
      }
      & .icon-title-wrap p {
        max-width: 190px;
        text-align: center;
        margin: 25px auto 0 auto;
        padding: 0;
      }
    }
  }

  .cta-w-image {
    .btn-primary {
      padding: 12px 40px;
    }
    & figure {
      position: relative;
      border-radius: 8px;
      @include respond-below(tablet) {
        background-color: transparent !important;

        &::after {
          position: absolute;
          height: 240px;
          width: calc(100% - 40px);
          margin-left: 20px;
          content: "";
          display: block;
          z-index: -1;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 8px;
        }
      }
    }
    @include respond-below(tablet) {
      .btn-primary {
        padding: 12px 30px;
      }
      margin-left: 0;
      margin-right: 0;
      article {
        margin-left: 20px;
        margin-right: 20px;
      }
    }
    @include respond-above(tablet) {
      & > * {
        width: calc((100% - 40px) / 2);
        flex-shrink: 0;
      }
    }
  }
}

.booking-page-setup,
.automate-your-front-desk,
.how-to-attract-customers {
  @include respond-above(tablet_max) {
    .card--default.card--feature {
      & .icon-title-wrap p {
        max-width: 200px;
      }
    }
  }
}

.guides-receive-payments,
.guides-qr-code,
.automate-your-front-desk,
.booking-page-setup,
.how-to-attract-customers {
  &.faq-root {
    .faq-section {
      h3 {
        font-size: 32px;
        line-height: 40px;
      }
    }
    @include respond-above(tablet) {
      .faq-navigation {
        li {
          border-bottom: 1.5px solid #edf0f2;
          padding-bottom: 15px;
        }
        a {
          color: #8996a3;
          transition: color 0.5s;
          &.selected {
            font-weight: bold;
            color: #303a47;
            span {
              color: $color-brand;
              vertical-align: top;
              font-size: inherit;
            }
          }
        }
        &.faq-navigation-fixed {
          position: fixed !important;
          top: 100px !important;
          width: 460px;
          height: 85%;
          overflow-y: scroll;
        }
        &.faq-navigation-sticky {
          position: absolute;
          width: 460px;
          bottom: 150px;
        }
      }
      .faq-content {
        article::before {
          display: block;
          content: " ";
          margin-top: 0px;
          height: 0px;
          visibility: hidden;
          pointer-events: none;
        }
        &.content-sticky {
          margin-left: 400px;
        }
        .faq {
          width: initial !important;
          flex-grow: 0;
        }
      }
    }

    @include respond-below(tablet) {
      .ff-euclid.text-grey {
        line-height: 2 !important;
      }
      .faq-section {
        border-bottom: 1.5px solid #edf0f2;
      }
      .faq-content {
        article {
          .faq-section {
            max-height: 0;
            overflow: hidden;
            transition: all 0.35s ease-in-out;
          }
          input {
            visibility: hidden;
          }
          .faq-section-header {
            position: relative;
            width: 100%;
            line-height: 28px !important;
            padding-right: 40px;
            &::after {
              position: absolute;
              top: 50%;
              display: inline-block;
              content: "";
              right: 0px;
              width: 24px;
              height: 24px;
              border: none;
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill='%2348A36C' fill-rule='evenodd' d='M2.204 8.136c-.272-.26-.272-.681 0-.941.244-.234.624-.257.896-.07l.086.07L12 15.64l8.814-8.445c.244-.234.625-.257.896-.07l.087.07c.244.234.268.599.073.859l-.073.082-8.867 8.495c-.246.236-.581.369-.93.369-.3 0-.588-.098-.819-.274l-.11-.095-8.867-8.495z' clip-rule='evenodd'/%3E%3C/svg%3E");
              transform: translateY(-50%);
              transition: all 0.35s;
            }
          }
          input:checked ~ .faq-section {
            max-height: initial;
            overflow: visible;
          }
          input:checked + .faq-section-header::after {
            top: 50%;
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
    }
    .bg-offset::after {
      height: 100px;
      bottom: 0;
      @include respond-below(tablet) {
        display: none;
      }
    }
    .apostrophe {
      font-size: 80px;
      font-family: Georgia, "Times New Roman", Times, serif;
      color: $color-brand;
      margin-left: -2px;
    }
    .apostrophe + blockquote.fs-italic {
      color: #8996a3;
    }
  }
  .unordered-bulletin {
    li {
      margin-bottom: 20px;
      &::before {
        content: "";
        font-size: 12px;
        display: inline-block;
        width: 8px;
        background: #1d90f5;
        height: 8px;
        margin-right: 18px;
        line-height: 20px;
        border: 1px solid $color-brand;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: -2px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .ticked-bulletin {
    > .none {
      &::before {
        content: "";
        display: none;
      }
      @include respond-below(tablet) {
        p,
        a {
          line-height: 24px;
          font-size: 12px;
        }
      }
    }
  }
  .square-list {
    > li {
      width: 120px;
      text-align: center;
    }
  }
  .info-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: $color-brand;
    border: 1px solid $color-brand;
    font-family: serif;
    text-align: center;
  }
}

.automate-your-front-desk {
  .avg-pay {
    font-size: 32px;
    font-weight: 600;
    line-height: 1.25;
    color: $color-brand;
  }
  .faq svg {
    width: 48px;
  }
  .faq-navigation {
    scroll-behavior: smooth;
  }
}

.how-to-attract-customers,
.booking-page-setup,
.guides-qr-code {
  .unordered-bulletin {
    li {
      padding-left: 20px;
      position: relative;
      margin-bottom: 16px;
      &::before {
        content: "";
        display: inline-block;
        width: 8px;
        background: #556784;
        height: 8px;
        margin-right: 18px;
        border-radius: 50%;
        border: 0;
        line-height: normal;
        text-align: center;
        position: absolute;
        top: 11px;
        left: 0;
      }
    }
  }
  .ordered-bulletin {
    li {
      padding-left: 40px;
      position: relative;
      &::before {
        content: attr(data-id);
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 18px;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        border: 2px solid;
        border-radius: 50%;
        text-align: center;
        position: absolute;
        top: 5px;
        left: 0;
      }
    }
  }
  #faq-id {
    h3 {
      font-size: 20px;
      line-height: 1.5;
    }
    h4 {
      font-size: 18px;
      line-height: 1.5;
      margin-bottom: 16px;
    }
    .bg-blue {
      h3 {
        font-size: 32px;
      }
    }
  }

  #easy-share {
    width: 50px;
  }

  #sub-faqs {
    padding-left: 24px;
    li {
      border-bottom: 0;
      padding-bottom: 0;
    }
    a {
      font-size: 18px;
      line-height: 1.5;
    }
  }
  .faq svg {
    @include respond-below(tablet) {
      width: 24px;
      height: 24px;
    }
  }
  @include respond-below(tablet) {
    .unordered-bulletin {
      li {
        margin-bottom: 16px;
      }
    }
  }
}
.booking-page-setup,
.guides-qr-code,
.how-to-attract-customers {
  .faq-section {
    h2 {
      font-size: 32px;
      line-height: 40px;
    }
  }
  #note {
    font-family: $default-font;
    font-size: 16px;
    line-height: 1.5;
    color: #8996a3;
  }

  .card-step {
    display: inline-block;
    width: 100%;
    padding: 20px 50px 24px 32px;
    border-radius: 8px;
    border: solid 1.5px #d9e1ee;
    font-family: $default-font;
    font-size: 18px;
    font-weight: bold;

    &:hover {
      background-color: #fff;
      box-shadow: 0 14px 32px 0 rgba(22, 45, 61, 0.08),
        0 1px 4px 0 rgba(22, 45, 61, 0.1);
      border-color: #fff;
    }

    @include respond-below(tablet) {
      padding: 20px 18px 24px 20px;
    }
  }
  @include respond-above(tablet) {
    &.faq-root {
      .faq-content {
        article::before {
          display: block;
          content: " ";
          margin-top: -120px;
          height: 120px;
          visibility: hidden;
          pointer-events: none;
        }
      }
    }
  }

  @include respond-below(tablet) {
    .highlight {
      h2 {
        font-size: 32px;
        line-height: 1.25;
      }
    }
  }
}

.guides-qr-code {
  #faq-id {
    h4 {
      font-size: 24px !important;
      line-height: 28px !important;
      @include respond-below(tablet) {
        font-size: 20px !important;
      }
    }
    .text-smx {
      font-size: 16px !important;
      line-height: 24px !important;
      @include respond-below(tablet) {
        font-size: 18px !important;
        line-height: 28px !important;
      }
    }
  }
}

.how-to-build-brand-loyalty {
  #faq-id {
    .child-heading + p {
      margin: 8px 0px !important;
    }
  }
}

.how-to-find-brand-ambassadors {
  .highlight {
    article {
      @include respond-between(tablet, tablet_max) {
        h2 {
          font-size: 32px !important;
          line-height: 40px !important;
        }
      }
      @include respond-below(tablet) {
        h2 {
          font-size: 24px !important;
          line-height: 32px !important;
        }
      }
    }
  }
}

.small-business-website {
  .text-smx {
    font-size: 16px !important;
    line-height: 24px !important;
  }
  .highlight-card {
    .icon-title-wrap {
      @include respond-above(tablet_max) {
        h3 {
          min-height: 80px !important;
        }
      }
    }
  }
}
.how-to-promote-your-business {
  #faq-id {
    article {
      padding-top: 0px !important;
      .cta-section {
        margin: 160px 0 160px 0 !important;
        padding-bottom: 0 !important;
      }
      .text-mdx {
        margin-top: 40px !important;
      }

      .video-highlight {
        margin-top: 40px;
      }
      &:nth-of-type(2) {
        padding-top: 80px !important;
      }
      &:last-child {
        padding-top: 80px !important;
      }
      .faq {
        margin-top: 24px !important;
      }
      @include respond-between(tablet, tablet_max) {
        padding-top: 40px !important;
        .cta-section {
          margin: 80px 0 80px 0 !important;
        }
        &:first-child {
          .faq-section {
            p {
              &:nth-child(1) {
                padding-top: 40px !important;
              }
            }
          }
        }
      }
      @include respond-below(tablet) {
        padding-top: 40px !important;
        .cta-section {
          margin: 80px 0 80px 0 !important;
        }
        &:first-child {
          padding: 80px 0 0 !important;
        }
      }
      @include respond-between(tablet_max, desktop) {
        padding-top: 40px !important;
        &:first-child {
          padding: 80px 0 0 !important;
        }
      }
    }
  }
}

.instagram-appointment-hacks {
  .hero--default {
    @include respond-above(desktop_sm) {
      h1 {
        font-size: 40px !important;
        line-height: 48px !important;
      }
      padding-bottom: 70px !important;
    }
    @include respond-below(tablet_max) {
      picture {
        img {
          border-radius: 8px !important;
        }
      }
    }
  }
  .faq-content {
    .sub-heading {
      padding-left: 0px !important;
    }
  }
}
.building-your-brand-from-scratch {
  .cta-section {
    @include respond-above(desktop_sm) {
      margin-top: 160px !important;
      margin-bottom: 160px !important;
      padding-bottom: 0px !important;
    }
    @include respond-below(tablet_max) {
      margin-block: 80px !important;
    }
    @include respond-below(desktop_sm) {
      .has-image {
        article {
          min-width: 300px !important;
          margin-right: 0px !important;
        }
      }
    }
    article {
      p:nth-of-type(2) {
        margin-bottom: 0 !important;
      }
    }
    h2 {
      font-size: 24px !important;
      line-height: 32px !important;
    }
  }
  .cta-contrast.has-image article {
    padding-bottom: 24px;
  }
  .cta-section .cta-contrast.has-image a {
    padding: 0px !important;
  }
  .table-new-design {
    ul {
      margin: 0px !important;
    }
    .unordered-bulletin.text-sm li::before {
      width: 6px;
      height: 6px;
      margin-right: 10px;
      top: 9px;
    }
    .unordered-bulletin.text-sm li {
      font-size: 14px !important;
      line-height: 24px !important;
      text-align: left;
      padding-left: 7px;
      margin-left: 10px;
    }
  }
  .faq-section .faq {
    margin-block: 40px !important;
  }
  .action-trigger::after {
    background-color: $green-500 !important;
  }
  .guide-cards .highlight-card:hover h3,
  .guide-cards .highlight-card.icon-title-wrap:hover,
  .action-trigger::after,
  .action-trigger__label {
    color: $green-500 !important;
  }
  .plan-table td {
    vertical-align: top !important;
  }
  @include respond-above(desktop_sm) {
    .highlight-card .icon-title-wrap h3 {
      min-height: 80px !important;
    }
  }
  .hero--default {
    @include respond-above(desktop_sm) {
      padding-bottom: 70px !important;
    }
    @include respond-below(tablet_max) {
      picture {
        img {
          border-radius: 8px !important;
        }
      }
    }
  }
  .faq-content {
    .sub-heading {
      padding-left: 0px !important;
    }
  }
}
