.promo-22 {
  &-banner {
    border: 1px solid #fff;
    background-color: rgba(206, 235, 233, 0.5);
    position: relative;
    top: 88px;
    width: auto;
    height: auto;
    border-radius: 8px;
    margin-top: 24px;
    @include z-index(1);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    @include respond-below(tablet) {
      top: 56px;
    }
    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      border-radius: 8px;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
    &:hover {
      box-shadow: 0 1px 2px 0 #303a4733, 0 1px 3px 0 #303a4733;
      &::before {
        opacity: 1;
      }
    }
    &.is-fixed {
      box-shadow: none;
    }
    .content-div {
      padding: 16px 0px 16px 24px;
      width: 96%;
    }
    .details {
      margin-right: 20px;
      color: #05857c;
      font-size: 18px;
      line-height: 28px;
      text-align: left;
      letter-spacing: normal;
      a {
        color: #05857c;
        border-bottom: solid 1px #05857c;
        height: 1px;
      }
    }
    .close-element {
      float: right;
      width: 24px;
      height: 24px;
      position: absolute;
      right: 14px;
      z-index: 10;
      svg:hover {
        cursor: pointer;
        transform: rotate(180deg);
        transition: all 0.3s ease-in-out;
      }
      @include respond-below(tablet) {
        top: 4px;
        right: 4px;
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

@keyframes slide {
  to {
    transform: translateY(0%);
  }
}
